import React, { useEffect, useState } from 'react';
// import { db } from "../firebase";
import { doc, getDoc } from 'firebase/firestore';
import './BusinessProfile.css';
import { db } from '../services/firebase';
import { Business } from '../types/business';
import { useParams } from 'react-router-dom';
import Spinner from '../components/Spinner';
import MapComponent from '../components/Map';

const BusinessProfile: React.FC = () => {
    const { businessId } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [business, setBusiness] = useState<Business>(null);

    useEffect(() => {
        const fetchBusiness = async () => {
            if (!businessId) return; // Ensure there's an ID
            setLoading(true);
            try {
                const docRef = doc(db, 'campaigns', businessId);
                const docSnap = await getDoc(docRef);
                console.log(docSnap);
                if (docSnap.exists()) {
                    const businessData = docSnap.data() as Business;
                    setBusiness(businessData);
                } else {
                    setError('Business not found');
                }
            } catch (error) {
                console.error('Error fetching business data:', error);
                setError('Failed to load business details');
            }
            setLoading(false);
        };

        fetchBusiness();
    }, [businessId]);

    if (loading) return <Spinner />;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className="container">
            <div className="business-info">
                <h1 className="business-name">{business.name}</h1>

                <img src={business.image} alt="Business" className="business-image-mobile" />

                <p className="business-description">{business.description}</p>

                <div className="business-contact">
                    <p>
                        <strong>Phone:</strong> <a href={`tel:${business.contact.phone}`}>{business.contact.phone}</a>
                    </p>
                    <p>
                        <strong>Website:</strong>{' '}
                        <a href={business.contact.website} target="_blank" rel="noopener noreferrer">
                            {business.contact.website}
                        </a>
                    </p>
                </div>

                <div className="map-container">
                    {business.location && <MapComponent lat={parseFloat(business.location.lat)} lng={parseFloat(business.location.lng)} />}
                </div>

                <div className="action-buttons">
                    <a href={business.googleMapsUrl} target="_blank" className="btn btn-directions" rel="noopener noreferrer">
                        Get Directions
                    </a>
                </div>
            </div>

            <div className="business-image-desktop" style={{ backgroundImage: `url(${business.image})` }}></div>
        </div>
    );
};

export default BusinessProfile;
