import './App.css';
import Header from './components/Header';
import BusinessProfile from './pages/BusinessProfile';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from './pages/Home';

function App() {
    return (
        <div>
            <Header />
            <Routes>
                {/* Home Page - Redirect or show general content */}
                <Route path="/" element={<HomePage />} />

                {/* Route for business profiles dynamically */}
                <Route path="/business/:businessId" element={<BusinessProfile />} />
            </Routes>
        </div>
    );
}

export default App;
