import React from "react";
import "./Home.css"; // Ensure this file exists

const HomePage = () => {
  return (
    <div className="homepage-container">

      <section className="hero-section">
        <h2>Smart Advertising for Small Businesses 🚀</h2>
        <p>Launch targeted ads in minutes. Affordable, effective, and hassle-free advertising for your business.</p>
      </section>

      <section className="features-section">
        <h2>How It Works</h2>
        <div className="features">
          <div className="feature-box">
            <h3>1. Create an Ad</h3>
            <p>Enter your business details, location, and budget.</p>
          </div>
          <div className="feature-box">
            <h3>2. Get Verified</h3>
            <p>Our team ensures quality and compliance.</p>
          </div>
          <div className="feature-box">
            <h3>3. Track Results</h3>
            <p>See real-time stats like impressions and clicks.</p>
          </div>
        </div>
      </section>

      <section className="why-adanera">
        <h2>Why Choose Adanera?</h2>
        <ul>
          <li>✅ No marketing experience needed – simple & automated</li>
          <li>✅ Low-cost entry – start from just $10</li>
          <li>✅ Multiple platforms – advertise where your customers are</li>
          <li>✅ Data-driven insights – track performance</li>
        </ul>
      </section>

      <section className="cta-section">
        <h2>Get Started</h2>
        <p>Adanera will soon be available on mobile! Stay tuned for our app launch.</p>
        <div className="app-links">
          <a href="#"><img src="app-store.png" alt="Download on App Store" /></a>
          <a href="#"><img src="google-play.png" alt="Get it on Google Play" /></a>
        </div>
      </section>

      <footer className="footer">
        <p>© 2025 Adanera. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default HomePage;