import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const mapContainerStyle = {
    width: '100%',
    height: '400px',
};

interface MapComponentProps {
    lat: number;
    lng: number;
}

const MapComponent: React.FC<MapComponentProps> = ({ lat, lng }) => {
    return (
        <LoadScript googleMapsApiKey="AIzaSyCZyIVW5zfXNJT2F8eSpVDTaDb4xgEK7xg">
            <GoogleMap mapContainerStyle={mapContainerStyle} center={{ lat, lng }} zoom={14}>
                <Marker position={{ lat, lng }} />
            </GoogleMap>
        </LoadScript>
    );
};

export default MapComponent;
