import React from "react";
import "./Header.css";

const Header: React.FC = () => {
  return (
    <header className="header">
      <h1>Adanera</h1>
      <nav className="nav-links">
        {/* <a href="#">Home</a>
        <a href="#">Listings</a>
        <a href="#">Contact</a> */}
      </nav>
    </header>
  );
};

export default Header;
